@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #ff0000;
  --secondary-color: #3e4b5c;
  --light-color: #fff2f7;
  --primary-text-color: #000000;
  --secondary-text-color: #fff;
  --error-color: #dc3545;
  --placeholder-color: #909090;
  --border-color: #a7b2c0;
  --highlight-color: #90d7e7;
}

body {
  background: #f3f5fa;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Inter" !important;
}

button {
  transition: all 0.5s ease-in-out;
}

.App {
  text-align: center;
}

.login-screen {
  padding: 20px;
}

.form-group span.error {
  color: var(--error-color);
  text-transform: none;
  font-size: 15px;
}

/* Header */
.header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: var(--primary-text-color);
  border-bottom: 1px solid #ddd;
}

.header-logo img {
  height: 48px;
}

.search-container {
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  height: 40px;
  padding: 0 13px;
  width: 336px;
}

.search-container input[type="text"] {
  background: #0000;
  border: 0;
  color: var(--border-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.645px;
}

.search-container input[type="text"]:focus {
  border: none;
  outline: none;
}

.promo-input:focus {
  outline: none;
  box-shadow: none;
}

.search-container .search-button {
  background: #0000;
  border: 0;
}

.header-user-profile {
  display: flex;
  align-items: center;
}

.login-page .header-user-profile,
.login-page .search-container,
.login-page .burger-menu {
  display: none;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background: var(--primary-color);
  padding: 5px;
}

.header-user-name {
  font-size: 14px;
  text-align: left;
  color: var(--secondary-text-color);
  text-transform: capitalize;
}

.header-user-dropdown {
  position: relative;
  margin-left: 10px;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.5s ease-in;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--secondary-text-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 150px;
  text-align: left;
}

.dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.dropdown-content a:hover {
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
}

.header-user-dropdown:hover .dropdown-content {
  display: block;
}

button.dropdown-button {
  color: var(--secondary-text-color);
}

/* Burger menu styles */
.burger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.burger-bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

.burger-menu,
.mobile-menu {
  display: none;
}

/* Login Page */
.login-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin: 2% 0;
}

.login-screen h1 {
  margin-bottom: 20px;
  color: #000;
  font-size: 48px;
  font-weight: 600;
  line-height: 20.752px;
  margin-bottom: 50px;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 600px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 10px;
  text-align: left;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group select,
.form-group input,
.form-group span input.ant-input {

  height: 70px;
  padding: 0 30px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  margin-bottom: 10px;
  background: transparent;
  font-family: "Poppins", sans-serif;
}

.form-group span input::placeholder {

  color: var(--placeholder-color);
}

.login-form .form-group span.ant-input-outlined {
  border: 0;
  padding: 0;
  border-radius: 4px;
  background: transparent;
  border: 1px solid var(--border-color);
}

.login-form .form-group span input.ant-input {
  border: 0;
  margin: 0;
  background: none;
}

.login-form .ant-input-affix-wrapper .ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 35%;
}

input:-internal-autofill-selected {
  background: none;
}

.form-group span {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.form-group button,
.login-form button {
  height: 80px;
  font-size: 16px;
  background: var(--primary-color);
  cursor: pointer;
  padding: 0 20px;
  border-radius: 4px;
  border: 0;
  color: var(--secondary-text-color);
  font-size: 32px;
  font-weight: 600;
  line-height: 20.75px;
  margin-top: 20px;
}

header button {
  height: auto;
}

button:hover {
  background-color: var(--secondary-color);
}

.main-screen {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 80vh;
  justify-content: space-between;
}



/* Sidebar */

/* .sidebar {
  min-width: 20%;
  background: var(--secondary-color);
} */

.sidebar {
  max-height: 80vh;
  overflow-y: auto;
  min-width: 20%;
  background: var(--secondary-color);
}

.sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar ul li {
  cursor: pointer;
  text-align: left;
  padding: 14px 25px;
  border-bottom: 2px solid var(--border-color);
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}

.sidebar ul li:hover {
  border-bottom: 2px solid var(--light-color);
  color: var(--light-color);
  background: var(--secondary-color);
}

.sidebar ul li.active {
  border-bottom: 2px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--secondary-text-color);
}

/* Product Catalog */
.product-catalog {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  padding: 20px;
  flex-direction: column;
}

.product-catalog h2 {
  text-align: left;
  color: var(--primary-text-color);
  font-size: 32px;
  font-weight: 600;
  line-height: 19.645px;
}

.cart {
  display: flex;
  width: 100%;
  min-height: 86vh;
  border-radius: 10px 10px 0 0;
  background: var(--secondary-text-color);
  box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: space-between;
}

.cartItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.empty-cart {
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-screen {
  min-height: 85vh;
}

.cart-page-layout {
  display: flex;
}

.cart-container {
  position: relative;
  width: 35%;
  background-color: var(--secondary-text-color);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Ensure it takes full height */
}

.left-cont {
  display: flex;
  flex: 1;
  align-items: stretch;
  justify-content: center;
}

.cart-button {
  display: none;
}

.tab-header {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.tab-header li {
  padding: 24px 20px;
  cursor: pointer;
  width: 100%;
  background: var(--secondary-color);
  color: var(--secondary-text-color);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  transition: background-color 0.3s, font-weight 0.3s;
  font:bold;
}

.tab-header li.active {
  background-color: var(--primary-color);
  color: var(--secondary-text-color);
}

.tab-content {
  position: relative;
}

.tab-pane {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.tab-pane.active {
  opacity: 1;
  transform: translateY(0);
  position: relative;
}

.quick-order {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 8px;
  /* padding: 20px 20px; */
  gap: 15px;
  /* gap: 20px; */
  flex-wrap: wrap;
}

.cart-search-cont {
  position: relative;
  flex: 1;
}

.cart-search {
  border-radius: 8px;
  border: 1px solid #D6DBE1;
  height: 50px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.cart-search input,
.cart-search button {
  height: 48px;
  border: 0;
  background-color: transparent;
  outline: none;
  color: var(--border-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  outline: none;
}

.ant-select-single {
  height: 100%;
  width: 100%;
}

.autoCompleteForm {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  border: #d6dbe1;
  background: var(--secondary-text-color);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
  gap: 20px;
  flex-direction: column;
  margin-top: 52px;
}

.autoCompleteForm .ant-input-outlined {
  background: var(--secondary-text-color);
  border-radius: 8px;
  display: flex;
  height: 52px;
  padding: 0 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border: 1px solid #d6dbe1;
  flex-direction: column;
  color: var(--border-color);
  font-size: 22px;
  font-weight: 500;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: var(--border-color);
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.autoCompleteForm .ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
  border-radius: 8px;
  background: var(--primary-color);
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}

.ant-select-dropdown .ant-select-item-option-content {
  color: var(--primary-text-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.search-customer-list {
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
}

.search-customer-list span {
  flex: 0.6;
  font-weight: 500;
}

.search-customer-list .search-cust-number::before {
  content: " ";
  background: #d6dbe1;
  width: 2px;
  height: 10px;
  margin: 0 35px 0 0;
  display: inline-block;
}

.no-data-found {
  color: var(--primary-text-color);
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.ant-btn-icon img {
  height: 20px;
}

.customer-suggestions {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: var(--secondary-text-color);
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.customer-suggestions li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.customer-suggestions li:hover {
  background-color: #f0f0f0;
}

.quick-order-btn {
  display: flex;
  width: auto;
  padding: 0 30px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4.8px !important;
  border: 1px solid var(--primary-color);
  background: var(--primary-color) !important;
  color: var(--secondary-text-color) !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  height: 48px !important;
  border: none !important;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  background: var(--secondary-color);
  height: 60px;
  align-items: center;
  padding: 0 20px;
  color: var(--secondary-text-color);
  white-space: nowrap;
  gap: 10px;
}

.cart-footer-cards {
  display: flex;
  justify-content: space-between;
  background: var(--secondary-color);
  height: 70px;
  align-items: center;
  padding: 7px 20px;
  color: var(--secondary-text-color);
  white-space: nowrap;
  gap: 10px;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.cart-header h3 {
  font-size: 23px;
}

.cart-head-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 20px 0 0; */
  font-size: 1rem;
}

span.cart-head-items {
  border-left: 1px solid var(--secondary-text-color);
  border-right: 1px solid var(--secondary-text-color);
  padding: 0 15px;
  margin: 0 15px;
}

span.cart-head-total {
  color: var(--light-color);
  font-weight: 500;
  line-height: 20px;
  font-size: 23px;
}

.cart-head-right {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 60px;
  border-left: 1px solid var(--secondary-text-color);
  /* padding: 0 0 0 20px; */
}

.cart-head-right button {
  color: var(--light-color);
  font-size: 18px;
  font-weight: 400;
  line-height: 56px;
  border: 0;
  background: transparent;
}

.cart-head-right button:disabled {
  opacity: 0.5;
}

.cart-head-right button:hover {
  color: var(--secondary-text-color);
}

.cart-head-right button:disabled:hover {
  cursor: not-allowed;
}

.cart-header h3 {
  margin: 0;
  padding: 0;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  max-height: 320px;
  min-height: 320px;
  overflow: auto;
}

.cart-body {
  padding: 0 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  overflow-y: auto;
  flex-grow: 1;
  max-height: 809px;
}

.cart-item-cont {
  min-height: 100px;
  max-height: 130px;
  overflow-y: auto;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.cart-item span {
  flex: 1;
  text-align: left;
  text-align: left;
  color: #232F3E;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}

.cart-item.cart-item-head {
  border-bottom: 1px solid #576474;
}

.cart-item.cart-item-head span {
  color: var(--border-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 0;
}

span.cart-item-unitprice {
  flex: 0.6;
  text-align: right;
}

span.cart-item-qty {
  flex: 0.6;
  text-align: center;
}

span.cart-item-totalprice {
  flex: 0.4;
  text-align: right;
}

span.cart-item-action {
  flex: 0.2;
  text-align: right;
}

span.cart-item-qty {
  align-items: center;
}

span.cart-item-qty.quantity-control {
  display: flex;
  width: auto;
  height: 23px;
  padding: 0 3px;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #D6DBE1;
}

span.cart-item-qty.quantity-control span {
  height: 100%;
  width: 20px;
  padding: 0;
  border-radius: 0;
  border: 0;
  color: var(--primary-text-color);
  font-family: Poppins;
  text-align: center;
  background: #D6DBE1;
  font-size: 15.845px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

span.cart-item-qty.quantity-control button {
  height: auto;
  padding: 0 5px;
  border-radius: 0;
  border: 0;
  background: transparent;
}

span.cart-item-qty.quantity-control button span {
  background: transparent;
  width: auto;
}

.quantity-control button {
  padding: 5px;
  margin: 0 5px;
}

.cart-sub-total {
  display: flex;
  width: 100%;
  padding: 15px 0px;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid #576474;
  border-bottom: 1px solid #576474;
  color: var(--primary-text-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.cart-summary {
  padding-top: 10px;
  border-top: 1px solid #576474;
  /* border-bottom: 1px solid #576474; */
}

.cart-summary div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #232F3E;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}

.deal-name {
  font-weight: 600;
}

.color-text {
  color: var(--highlight-color);
}

.promo-code-popup {
  width: 35%;
}

.promo-code-popup .ant-modal-content {
  padding: 0;
  background: #F3F5FA;
}

.ant-modal .ant-modal-close {
  padding: 0;
  border-radius: 10px;
}

/* .promo-code-header {
  padding: 40px 40px;
  border-radius: 20px 20px 0 0;
  background: var(--secondary-text-color);
}

.promocode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.promocode div {
  width: 30%;
  text-align: center;
}

.promocode label {
  color: #576474;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
} */
.promo-code-header {
  padding: 40px 40px;
  border-radius: 20px 20px 0 0;
  background: var(--secondary-text-color);
}

.promocode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  gap: 0; /* Add this line to remove any gap between flex items */
}

.promocode div {
  width: 48%;
  text-align: center;
  margin: 0; /* Ensure no margin is applied */
  padding: 0; /* Ensure no padding is applied */
}

.promocode label {
  color: #576474;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.promo-input-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo-input-wrap input {
  margin: 0;
  padding: 0;
}


span.promo-input-wrap {
  border-radius: 100px;
  border: 1px solid #D6DBE1;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

span.promo-input-wrap input[type="text"] {
  border-radius: 100px 0 0 100px;
  width: 100%;
  border: 0;
  padding: 0 0 0 5px;
  color: var(--highlight-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  outline: none;
}

span.promo-input-wrap input[type="text"]::placeholder {
  color: #576474;
}

span.promo-input-wrap button {
  border-radius: 100px;
  width: 28px;
  height: 28px;
  border: 0;
  padding: 0;
}

span.promo-input-wrap button img {
  border-radius: 100px;
  width: 28px;
  height: 28px;
  border: 0;
}

.promo-code-box {
  border-radius: 10px;
  background: #F3F5FA;
}

.promoCodeContainer {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid #D6DBE1;
}

.promoCodeContainer input {
  flex: 1 1;
  height: 100%;
  padding: 0 20px;
  border: 0;
  color: var(--border-color);
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
}

.promoCodeContainer button.ant-btn.ant-btn-default {
  color: #576474;
  font-weight: 500;
  font-size: 24px;
  height: 100%;
  border: 0;
  background: transparent;
  text-transform: uppercase;
  padding: 0 30px;
}

.order-controls {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

button.ant-modal-close {
  /* display: none; */
  height: initial !important;
}

.promocode .input-box {
  height: 40px;
  border-radius: 100px;
  border: 1px solid #D6DBE1;
}

.couponCodesList {
  padding: 30px 40px;
}

.couponCodesList ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.coupon-code-box {
  display: flex;
  height: 150px;
  border-radius: 20px;
  background: var(--secondary-text-color);
}

/* .coupon-code-box .coupon-left {
  display: flex;
  width: 75px;
  color: var(--secondary-text-color);
  height: auto;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0px 0px 20px;
  background: var(--primary-color);
} */
.coupon-code-box .coupon-left {
  align-items: center;
  background: red;
  background: var(--primary-color);
  border-radius: 20px 0 0 20px;
  color: #fff;
  color: var(--secondary-text-color);
  display: flex;
  height: auto;
  justify-content: center;
  white-space: nowrap;
  min-width: 75px;
}

.coupon-code-box .coupon-left h3 {
  transform: rotate(-90deg);
  /* font-size: 24px; */
  font-size: 16px;
  white-space: nowrap;
  font-weight: 800;
}

.coupon-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;
}

.coupon-code-small {
  transform: rotate(-90deg);
  /* font-size: 18px; */
  white-space: nowrap;
  font-weight: 800;
}

.coupon-code-medium {
  transform: rotate(-90deg);
  font-size: 16px;
  white-space: nowrap;
  font-weight: 800;
}

.coupon-code-large {
  transform: rotate(-90deg);
  font-size: 14px;
  white-space: nowrap;
  font-weight: 800;
}

/* .code-apply {
  border-bottom: var(--border-color) 1px dashed;
  padding: 0 0 5px 0;
  color: var(--primary-text-color);
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
} */

.code-apply {
  border-bottom: 1px dashed #A7B2C0;
  border-bottom: 1px dashed var(--border-color);
  color: #000;
  color: var(--primary-text-color);
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 700;
  justify-content: space-between;
  line-height: normal;
  padding: 0 0 5px;
  align-items: center;
}

.code-apply .apply-btn {
  color: #576474;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.code-apply .apply-btn:hover {
  color: #576474;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

/* .code-desc {
  color: #576474;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  overflow-y: scroll;
} */
.code-desc {
  color: #576474;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  overflow-y: scroll;
}

.cart-footer {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.cart-grand-total {
  color: var(--primary-text-color);
  font-size: 25px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
}


.order-controls {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 70%;
}

.order-controls .swiper {
  width: 100%;
}

.order-controls button {
  flex: 1 1;
  display: flex;
  height: 50px;
  padding: 10px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 2px solid #A7B2C0;
  color: #fff;
  background: transparent;
  font-size: 25px;
  font-weight: 600;
  line-height: 21px;
  justify-content: space-between;
  transition: all 0.5s ease-in;
}

.order-controls button img {
  opacity: 0.4;
}

.order-controls button:hover {
  background-color: #e0e0e0;
}

.order-controls button.ant-btn-default.cash-card-btn:hover {
  border-radius: 12.676px;
  border: 2px solid var(--primary-color);
	background: var(--light-color);
  color: var(--primary-text-color);
}

.order-controls button.ant-btn-default.cash-card-btn:hover img {
  opacity: 1;
}


.place-order {
	margin: 6px;
}

.place-order button.ant-btn {
  border-radius: 10px;
  background: var(--primary-color);
  display: flex;
  width: 100%;
  height: 70px;
  flex-shrink: 0;
  color: var(--secondary-text-color);
  font-size: 24px;
  font-weight: 600;
  border: 0;
  border: 1px solid var(--primary-color);
}

.place-order button.ant-btn:disabled {
  opacity: 0.4;
}

.place-order button.ant-btn:hover {
  background: var(--secondary-text-color);
}

.place-order button.ant-btn:disabled:hover {
  background: var(--primary-color);
  opacity: 0.4;
}

.catalog-container {
  display: flex;
  flex-wrap: wrap;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-around;
  gap: 20px;
  justify-content: flex-start;
}

.product-card {
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  background-color: var(--secondary-text-color);
  transition: box-shadow 0.2s ease;
	width: 23%;
  margin: 0;
  height: 260px;
  position: relative;
}

.quantity-bubble {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color:var(--primary-color);
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
}

.product-card:hover {
  border-radius: 4.114px;
  border: 2.057px solid var(--primary-color);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.product-image {
	width: 100%;
	min-height: 100px;
  background-color: #fdfff4;
}

.product-image img {
  width: 100% !important;
  height: 100px;
}

.product-details {
  text-align: left;
  padding: 10px 0;
}

.product-type {
  color: var(--border-color);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  height: 22px;
  display: block;
}

h4.product-name {
  color: #576474;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  height: 45px;
}

.product-qty {
  color: #576474;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  height: 25px;
  display: block;
}

.price-addbtn {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  align-items: center;
}

.product-price {
  color: var(--primary-text-color);
  padding-top: 10px;
  font-size: 15px; /* Fixed font size for better visibility */
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.add-button {
  margin-top: 10px;
  border: none;
  padding: 2px 8px;
  height: auto;
  border-radius: 100px;
  background: var(--primary-color);
  color: var(--secondary-text-color);
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.645px;
}

.add-button:hover {
  background-color: var(--secondary-color);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.cash-popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 60px;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-main {
  display: flex;
  gap: 40px;
}

.popup-left-cont {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: space-between;
}

.popup-row {
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #D6DBE1;
  display: flex;
  height: 70px;
  padding: 0 25px;
  align-items: center;
  color: var(--primary-text-color);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  gap: 25px;
}

.popup-row label {
  margin-right: 10px;
}

.popup-row input {
  flex: 1;
  text-align: right;
  border: none;
  background: none;
  font-size: 36px;
  outline: none;
  padding: 0;
}

.popup-row input:focus {
  border-color: transparent;
  box-shadow: none;
  outline: 0;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-outlined:hover {
  background-color: transparent !important;
  border: 0;
}

.keypad {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 40%;
  gap: 15px;
}

.keypad button {
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #F3F5FA;
  color: var(--primary-text-color);
  font-size: 31px;
  font-weight: 500;
  width: 65px;
  height: 65px;

}

.keypad button.clearbtn {
  font-size: 20px;
}

.popup-buttons {
  display: flex !important;
  justify-content: space-between !important;
}

/* .popup-buttons button {
  width: 48% !important;
} */

button.close-calc {
  position: absolute;
  width: auto;
  height: auto;
  padding: 0;
  border-radius: 100px;
  right: 0;
  margin: -70px -20px;
  border: 0;
}

button.close-calc:hover {
  border: none;
}

button.ok-btn {
  background: var(--primary-color);
  text-transform: uppercase;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
/* Product PopUp CSS Starts from Here */

/* .product-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  border-radius: 8px;
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.popup-head {
  display: flex;
  justify-content: flex-end;
}

.popup-close {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.heading-img {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.heading-img span {
  flex: 0.6 1;
  border-radius: 3px;
  background: var(--light-color);
  padding: 5px 25px;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
}

.heading-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.heading-img h2 {
  margin: 0;
  color: var(--primary-text-color);
  font-family: Inter;
  font-size: 25px;
  font-weight: 600;
}

.popup-body {
  display: flex;
  flex-direction: column;
}

.product-item {
  display: flex;
  justify-content: space-between;
}

.product-item span {
  color: #576474;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}

.product-item span.select-attr {
  justify-content: flex-start;
  gap: 10px;
}

.product-item span.select-attr p {
  margin: 0;
  text-align: left;
}

.popup-qty {
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: flex-end;
}

.popup-qty label {
  font-size: 16px;
  margin-right: 10px;
}

.popup-qty .quantity-control {
  display: inline-flex;
  height: 52px;
  padding: 0 10px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 135px;
  border: 1px solid #D6DBE1;
}

.popup-qty input {
  text-align: center;
  display: flex;
  width: 65px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background: #D6DBE1;
  border: 0;
  height: 100%;
  color: var(--primary-text-color);
  font-size: 20px;
  font-weight: 500;
}

.popup-qty button {
  border: none;
  padding: 0 10px;
  margin: 0;
  cursor: pointer;
  color: var(--primary-text-color);
  font-family: Poppins;
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.721px;
  background: transparent;
}

.btn-total {
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: left;
}

.btn-total span {
  font-size: 22px;
  font-weight: 700;
}



.popup-footer {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--primary-color);
  padding: 10px 30px;
  align-items: center;
  gap: 10px;
  height: 70px;
}

.popup-footer button {
  color: var(--secondary-text-color);
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background: transparent;
  border: none;
}

.popup-footer button:hover {
  color: var(--light-color);
}

.warehouse-details {
  margin-top: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  max-height: 150px;
  overflow-y: auto; 
}

.warehouse-details table {
  width: 100%;
  border-collapse: collapse;
}

.warehouse-details th,
.warehouse-details td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #333;
  font-size: 16px;
}

.warehouse-details th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.warehouse-details tr:last-child td {
  border-bottom: none;
}

.price-details {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  max-height: 100px; 
  overflow-y: auto; 
}

.price-details p {
  margin: 0 0 10px 0; 
  font-size: 18px;
  color: #333;
}

.price-details span {
  font-weight: bold;
  color: #e74c3c;
} */
/* General Styles */
.product-popup {
  width: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.popup-head {
  display: flex;
  justify-content: flex-end;
}

.popup-close img {
  width: 20px;
  cursor: pointer;
}

.popup-body {
  margin-top: 20px;
}

.heading-img {
  display: flex;
  align-items: center;
}

.heading-img span {
  flex-shrink: 0;
}

.heading-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.heading-img h2 {
  font-size: 24px;
  color: #333;
}

.warehouse-details {
  margin-top: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  max-height: 150px;
  overflow-y: auto;
}

.warehouse-details table {
  width: 100%;
  border-collapse: collapse;
}

.warehouse-details th,
.warehouse-details td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #333;
  font-size: 16px;
}

.warehouse-details th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.warehouse-details tr:last-child td {
  border-bottom: none;
}

.price-details {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  max-height: 100px;
  overflow-y: auto;
}

.price-details p {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #333;
}

.price-details span {
  font-weight: bold;
  color: #e74c3c;
}

.popup-qty {
  margin-top: 20px;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  padding: 10px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.quantity-control input {
  text-align: center;
  padding: 8px;
  width: 50px;
  font-size: 16px;
  margin: 0 10px;
  border: 1px solid #ddd;
}

.popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.btn-total {
  font-size: 18px;
  color: #333;
}

.btn-total span {
  font-weight: bold;
  color: #e74c3c;
}

.popup-footer button {
  padding: 10px 20px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .product-popup {
    width: 90%;
    padding: 15px;
  }

  .heading-img img {
    width: 80px;
    height: 80px;
    margin-right: 15px;
  }

  .heading-img h2 {
    font-size: 20px;
  }

  .warehouse-details th,
  .warehouse-details td {
    font-size: 14px;
    padding: 8px 6px;
  }

  .price-details p {
    font-size: 16px;
  }

  .quantity-control input {
    width: 40px;
  }

  .popup-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-total {
    margin-bottom: 10px;
  }

  .popup-footer button {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .product-popup {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;
  }

  .heading-img img {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .heading-img h2 {
    font-size: 18px;
  }

  .warehouse-details th,
  .warehouse-details td {
    font-size: 12px;
    padding: 6px 4px;
  }

  .price-details p {
    font-size: 14px;
  }

  .quantity-control input {
    width: 35px;
  }

  .popup-footer {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-total {
    margin-bottom: 10px;
  }

  .popup-footer button {
    width: 100%;
    text-align: center;
  }
}
/* Close Button Styles */
.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #e74c3c; /* Red background */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none !important;
}

.popup-close img {
  width: 16px;
  height: 16px;
  filter: invert(1); 
  border: none;/* To make the icon white */
}

.popup-close:hover {
  background-color: #c0392b; /* Darker red on hover */
}






/* Product PopUp CSS Ends  Here */








.main-cont {
  padding: 55px;
  margin-bottom: 140px;

}

.main-cont h1 {
  text-align: left;
  color: #000011;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 50px;
}

.heading-cont {
  display: flex;
  justify-content: space-between;
}

.searchField {
  display: flex;
  border-radius: 8px;
  height: 60px;
  border: 1px solid var(--border-color);
  padding: 0 20px;
}

.searchField input {
  display: flex;
  min-width: 500px;
  height: 58px;
  padding: 18px 20px 18px 0;
  align-items: center;
  gap: 10px;
  border: 0;
  background: transparent;
  color: var(--border-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;
}

.searchField button {
  background: transparent;
  height: 60px;
  padding: 0;
  margin: 0;
  border: 0;
}

/* Customer Page */
.customer-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0;
  overflow: auto;
  max-height: 300px;
  /* padding-bottom: 300px; */
  padding-bottom: 150px;
}

.promo-header {
  display: flex;
  align-self: flex-start;
}

.customer-list li {
  display: flex;
  width: 30%;
  height: 150px;
  padding: 25px 20px;
  flex-direction: column;
  border-radius: 6.956px;
  border-left: 6px solid var(--Primary, var(--primary-color));
  background: var(--secondary-text-color);
  align-items: flex-start;
  gap: 26.954px;
  justify-content: center;
}

.customer-list li p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #576474;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  font-size: 26px;
}

.customer-list li p .cus-name {
  color: var(--secondary-color);
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0.936px;
  text-transform: capitalize;
  text-align: left;
}

.customer-list li p .cus-mobile {
  color: var(--primary-text-color);
  font-weight: 600;
}

.customer-list li p img {
  width: 36px;
  height: 36px;
}

.customer-list li p .cus-email {
  color: #576474;
  font-size: 24px;
  font-weight: 400;
}

button.cus-edit {
  background: none;
  border: 0;
}

.edit-customer-modal {
  width: 35%;
}

.edit-customer-modal .ant-modal-content {
  padding: 0 0 50px 0;
}

.edit-customer-modal .ant-modal-title {
  border-radius: 8px 8px 0px 0px;
  background: #F1F4F8;
  color: var(--primary-text-color);
  height: 80px;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .edit-customer-modal button.ant-modal-close {
  position: absolute;
  margin: -20px;
  background: #000;
  border-radius: 100px;
} */

.edit-customer-modal button.ant-modal-close .ant-modal-close {
  height: 48px;
  width: 48px;
  background: #000;
  border-radius: 100px;
}

.edit-customer-modal button.ant-modal-close .ant-modal-close .anticon svg {
  display: inline-block;
  color: #d9d9d9;
  font-size: 25px;
  font-weight: 600;
}

.edit-customer-modal.ant-modal .ant-modal-body {
  padding: 40px 60px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.edit-customer-modal.ant-modal input[type="text"] {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #d6dbe1;
  color: #576474;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.78px;
  text-transform: capitalize;
}

.edit-customer-modal.ant-modal input[type="email"] {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #d6dbe1;
  color: #576474;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.78px;
  text-transform:lowercase;
}

.ant-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 60px;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled) {
  width: 100%;
  /* height: 60px;   */
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--secondary-text-color);
  font-size: 24px;
  font-weight: 600;
  border-radius: 4px;
  background: var(--primary-color);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  opacity: 0.7;
  color: var(--secondary-text-color);
  background: var(--primary-color);
}

/* Order Page */
.ant-tabs-nav {
  border-bottom: #d6dbe1 1px solid;
}

.ant-tabs-nav .ant-tabs-tab-btn {
  gap: 10px;
  flex-shrink: 0;
  background: transparent;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs .ant-tabs-tab {
  padding: 0;
  margin: 0 8px 0 0;
}

.ant-tabs-nav .ant-tabs-tab-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid #576474;
  background: transparent;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.624px;
  text-transform: uppercase;
  cursor: pointer;
}

.order-page .ant-tabs-nav .ant-tabs-tab-btn span:hover,
.order-page .ant-tabs-tab-active span {
  color: var(--secondary-text-color) !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #576474;
}

.order-page .ant-tabs-nav .tab-parked {
  color: #576474;
}

.order-page .ant-tabs-nav .tab-parked:hover,
.order-page .ant-tabs-tab-active .tab-parked {
  background: #576474;
}

.order-page .ant-tabs-nav .tab-failed {
  border: 1px solid #ff0000;
  color: #ff0000;
}

.order-page .ant-tabs-nav .tab-failed:hover,
.order-page .ant-tabs-tab-active .tab-failed {
  background: #ff0000;
}

.order-page .ant-tabs-nav .tab-complete {
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);
}

.order-page .ant-tabs-nav .tab-complete:hover,
.order-page .ant-tabs-tab-active .tab-complete {
  background: var(--highlight-color);
}

.order-page .tab-content {
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
}

.tab-inner-cont {
  /* display: flex;
  flex-wrap: wrap;
  gap: 60px;
  overflow: auto;
  max-height: 800px; */
  /* padding-bottom: 600px; */

  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  overflow: auto;
  max-height: 225px;
  flex-wrap: wrap;
  padding-bottom: 10rem;
}

.no-data {
  font-size: 30px;
  text-align: center;
  width: 100%;
  color: #3c3232;
  font-weight: 500;
}

.order-box {
  border-radius: 6px;
  border-left: 6px solid #576474;
  background: var(--secondary-text-color);
  display: flex;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 30%;
  cursor: pointer;
}

.content-failed .order-box {
  border-left: 6px solid #ff0000;
}

.content-complete .order-box {
  border-left: 6px solid var(--highlight-color);
}

.content-complete .order-box.returned {
  border-left: 6px solid #ccf5dc;
}

.content-complete .order-box.returned p,
.content-complete .order-box.returned p span,
.content-complete .order-box.returned p.order-date {
  color: #b9babd
}

.content-complete .order-box.returned p span p.return-status {
  opacity: 1;
}

.order-box .ant-card-body p {
  display: flex;
  color: var(--primary-text-color);
  padding: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0.624px;
  margin: 0;
  text-transform: capitalize;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.order-box .ant-card-body p.return-status {
  border-radius: 100px;
  height: 25px;
  white-space: nowrap;
  padding: 0 20px;
	border: 1px solid rgba(242, 78, 30, 0.3);
	background: rgba(242, 78, 30, 0.1);
	color: #ff0000;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.order-box .ant-card-body p.order-date {
  color: #576474;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}

.order-box .ant-card-body p .light-text {
  color: #576474;
}

.order-box .ant-card-body a {
  color: #ff0000;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
}

.order-detail .ant-modal-content {
  padding: 40px;
}
span.amountAndDate {
	display: flex;
	display: flex;
	color: var(--primary-text-color);
	padding: 0;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 39px;
	letter-spacing: 0.624px;
	margin: 0;
	text-transform: capitalize;
	align-items: center;
	white-space: nowrap;
}

button.ant-modal-close span.ant-modal-close-x {
	background: #232f3e;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin: -39px 18px;
}

button.ant-modal-close span.ant-modal-close-x .anticon svg {
  color: #d9d9d9;
  font-size: 25px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-modal .ant-modal-close:hover {

  background-color: transparent;
}

/* Order Detail PopUp Starts here */

/* .order-detail h3 {
  display: flex;
  color: var(--primary-text-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0.624px;
  text-transform: capitalize;
  justify-content: space-between;
}

.order-detail p {
  display: flex;
  line-height: normal;
  align-items: center;
}

p.order-time {
  color: #576474;
  font-size: 18px;
  font-weight: 500;
}

p.order-cus-details {
  color: var(--primary-text-color);
  font-size: 24px;
  font-weight: 600;
}

p.order-cus-details span {
  color: #576474;
  padding: 0 0 0 20px;
}

p.order-cus-details span::before {
  content: "";
  background: #D6DBE1;
  width: 2px;
  height: 15px;
  position: absolute;
  margin: 10px -10px;
}

.order-items {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-top: 1px solid #D6DBE1;
  border-bottom: 1px solid #D6DBE1;
  padding: 20px 0;
  margin: 15px 0;
}

ul.order-items li {
  list-style: none;
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  align-items: center;
}

.order-pricing p {
  display: flex;
  justify-content: space-between;
  color: #576474;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
}

ul.order-items li span {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

}

ul.order-items li span.select-name {
  display: flex;
  gap: 10px;
  width: 50%;

}

span.prod-detail-price {
  width: 15%;
  text-align: right;
  color: #576474;
}


.custom-checkbox .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 2px solid #ddd;
  transition: background-color 0.2s, border-color 0.2s;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFD700;
  border-color: #FFD700;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 35%;
  top: 50%;
  width: 8px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(45deg) scale(1) translate(-70%, -50%);
}

ul.order-items li .return-qty {
  width: 40px;
  border-radius: 4px;
	border: 1px solid #d6dbe1;
  color: #576474;
  text-align: center;
  font-size: 16px;
  
  height: 40px;
}

.return-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.return-btns button {
  display: flex;
  height: 40px;
  padding: 20px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
	background: #ff0000;
  color: var(--secondary-text-color);
  font-size: 18px;
  font-weight: 400;
  border: 0;
}

.order-detail.returned .return-btns,
.order-detail.returned .ant-checkbox [class^="ant-checkbox"] {
  display: none !important;
}

.return-btns button:disabled {
  opacity: 0.2;
}


.return-msg {
  color: #ff0000;
  padding: 0 0 10px 0;
  display: block;
  font-size: 16px;
}

.return-msg .returned-items h4 {
  font-size: 20px;
}

.loading-message {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  z-index: 1000;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message p {
  font-size: 48px;
}

.returned-items {
  padding: 0 0 0 20px;
  font-size: 48px;
} */


.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px 0;
  background-color: #f8f8f8;
  position: sticky !important;
  bottom: 0;
  z-index: 10;
  width: 100%;
  box-shadow: 5px 0px 20px 0px #00000026;
}
 .order-detail h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-text-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 10px;
}

.order-detail h3 span {
  font-weight: 700;
  color: #ff0000;
}

.order-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #576474;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.order-cus-details {
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.order-cus-details span {
  color: #576474;
  padding-left: 20px;
  position: relative;
}

.order-cus-details span::before {
  content: "";
  background: #D6DBE1;
  width: 2px;
  height: 15px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.order-items {
  list-style: none;
  padding: 20px 0;
  margin: 15px 0;
  border-top: 1px solid #D6DBE1;
  border-bottom: 1px solid #D6DBE1;
}

.order-items li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.order-items li:last-child {
  margin-bottom: 0;
}

.select-name {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.prod-detail-price {
  font-weight: 500;
  color: #576474;
  padding: 10px;
}

.return-qty {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d6dbe1;
  color: #576474;
  text-align: center;
  font-size: 16px;
  padding: 0 5px;
}

.order-pricing p {
  display: flex;
  justify-content: space-between;
  color: #576474;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.order-pricing p:last-child {
  font-weight: 700;
  color: var(--primary-text-color);
}

.return-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.return-btns button {
  padding: 12px 20px;
  border-radius: 8px;
  background: #ff0000;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.return-btns button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.return-btns button:not(:disabled):hover {
  background-color: #d83e0f;
}

@media (max-width: 768px) {
  .order-detail h3 {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-time {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-cus-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-cus-details span {
    padding-left: 0;
    margin-top: 5px;
  }

  .order-cus-details span::before {
    display: none;
  }

  .order-items li {
    flex-wrap: wrap;
  }

  .select-name {
    width: 100%;
    margin-bottom: 10px;
  }

  .return-btns {
    flex-direction: column;
    gap: 10px;
  }

  .return-btns button {
    width: 100%;
  }
}
/* Order Detail PopUp ends here */
.cart-page-layout .footer {
  position: relative;
  box-shadow: -7px -8px 16px 0px #00000026;
}



.account-page {
  display: flex;
  text-align: left;
  align-items: stretch;
  height: 100vh;
  background: var(--secondary-text-color);
}

.profile-sidebar {
  width: 17%;
  background: #576474;
}

.profile-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.profile-sidebar ul li span,
.profile-sidebar ul li a {
  color: var(--secondary-text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  padding: 20px 30px;
  border-bottom: 1px solid var(--border-color);
  background: #576474;
  display: flex;
  gap: 10px;
  white-space: nowrap;
  text-decoration: none;
}

.profile-sidebar ul li span:hover,
.profile-sidebar ul li a:hover {
  border-bottom: 1px solid var(--light-color);
  color: var(--light-color);
}

.profile-sidebar ul li:hover svg {
  background: #000;
}

.profile-sidebar ul li.active {
  background: var(--primary-color);
  color: var(--secondary-text-color);
}

.sidebar ul li.active {
  font-weight: bold;
}

.account-page .content-right {
  flex: 1;
}

.account-page .content-right h3 {
	background: #f1f4f8;
  height: 80px;
  line-height: 80px;
  padding: 0 50px;
  margin-bottom: 50px;
}

.account-page .content-right .content {
  padding: 0 30px;
  color: var(--primary-text-color);
}

.account-page .content-right .content h4 {
  font-size: 36px;
  font-weight: 600;
}

.account-page .content-right .content p.profile-manager {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

.account-page .content-right .content p.profile-mobile {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.account-page .content-right .content p.profile-email {
  color: #576474;
  font-size: 24px;
  font-weight: 400;
}

.account-page .content input[type="text"],
.account-page .content input[type="password"] {
  display: flex;
  width: 600px;
  height: 60px;
  padding: 10px 30px;
  margin-bottom: 30px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Hint-2, var(--border-color));
}

.account-page .content button {
  display: flex;
  width: 600px;
  height: 70px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  gap: 10.563px;
  border-radius: 4px;
  background: var(--primary-color);
  color: var(--primary-text-color);
  border: 0;
  font-size: 24px;
  font-weight: 600;
}


.footer-nav {
  display: flex;
  justify-content: space-evenly;
  width: auto;
  gap: 60px;
  align-items: flex-end;
  list-style: none;
  margin: 0px 7px;
  padding: 0;
}

.footer-nav li {
  padding: 0;
}

.footer-nav-item {
  border-bottom: 2px solid #f8f8f8;
  text-align: center;
  flex: 1;
  color: var(--primary-text-color);
  text-decoration: none;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  opacity: 0.6;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 0;
  white-space: nowrap;
  transition: all 0.5s ease-in;
}

.footer-nav-item:hover {
  color: var(--primary-text-color);
  opacity: 1;
  border-bottom: 2px solid var(--primary-color);
}

.footer-nav-item.active {
  color: var(--primary-text-color);
  opacity: 1;
  border-bottom: 2px solid var(--primary-color);
}

.menu-button {
  position: fixed;
  top: 100px;
  left: 10px;
  z-index: 1000;
  display: none;
}

.menu-button.ant-btn.ant-btn-icon-only {
  width: 50px;
  padding-inline: 0;
  height: 33px;
  /* background: var(--primary-color); */
  border-radius: 1rem;
}

.ant-modal {
  top: 25%;
  width: 35%;
}

.loading-spin {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-error-modal {}

.customer-error-modal.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
  color: #232f3e;
  position: absolute;
  right: -20px;
  top: -10px;
  width: 40px;
  height: 40px;
  display: none;
}

.customer-error-modal.ant-modal-confirm-error .ant-modal-confirm-body>.anticon .anticon svg {
  font-size: 40px;
}

.customer-error-modal.ant-modal-confirm-error .ant-modal-confirm-title {
  color: var(--error-color);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
  text-align: center;
}

.customer-error-modal.ant-modal-confirm-error .anticon+.ant-modal-confirm-paragraph {
  max-width: 100%;
}

.customer-error-modal.ant-modal-confirm-error .ant-modal-confirm-content,
.ant-modal-confirm-success .ant-modal-confirm-content {
  font-size: 22px;
  text-align: center;
  width: 100%;
}

.ant-modal-confirm .ant-modal-confirm-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  height: auto;
  line-height: normal;
}

.ant-modal-confirm .ant-modal-confirm-title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 25px;
  line-height: 1.5;
  text-align: center;
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
  color: #52c41a;
  font-size: 40px;
  margin: 0 0 20px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: end;
  margin-top: 30px;
}

.ant-modal-confirm .anticon+.ant-modal-confirm-paragraph {
  width: 100%;
}

.ant-modal-confirm-success .ant-modal-confirm-content p {
  display: flex;
  justify-content: space-between;
}

span.text-red {
  color: var(--error-color);
}

.error-message {
  color: red;
  font-size: 14px;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: 2px;
}

.product-card.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.product-card .add-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.cart-place-order {
  border-right: 1px solid var(--secondary-text-color);
  height: 56px;
  align-items: flex-start;
  padding-right: 20px;
}

.order-request {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
}

.footer-label {
  display: flex;
  align-self: center;
}

.pagination button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button.next {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.pagination button.prev {
  background-color: #ddd;
  color: black;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.pagination button.prev:hover,
.pagination button.next:hover {
  background-color: transparent;
  color: var(--primary-color);
  border: var(--primary-color);
}

.pagination button:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

.pagination .page-number {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f8f8f8;
  color: black;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  min-width: 60px;
}

/* .button-container {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.icon-button {
	background: none;
	border: none;
	margin-left: 10px;
	cursor: pointer;
}

.unit-price-input {
	border: none;
	text-align: end;
}

.Auth-input {
	height: 45px;
	margin-bottom: 20px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
} */

@media (max-width: 1930px) {
  .product-catalog {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    padding: 20px;
    flex-direction: column;
  }
}

/* @media (max-width:1761px){

} */


@media (max-width: 1730px) {
  .order-box .ant-card-body p.status-row {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -5px;
  }

  .cart-body {
    max-height: 383px;
  }

  .order-box .ant-card-body p.order-date {
    color: #576474;
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
  }

  /* .product-image img {
    width: auto;
    height: 71px;
  } */

  .order-controls button {
    padding: 0px 3px;
    font-size: 20px;
    justify-content: center;
  }
  .order-controls button img {
    opacity: 0.4;
    width: 34px;
  }
}

@media (max-width: 1630px) {
  .order-controls button {
    padding: 0px 3px;
    font-size: 20px;
    justify-content: center;
  }
  .order-controls button img {
    opacity: 0.4;
    width: 34px;
  }
}


@media (min-width: 1025px) and (max-width: 1400px) {

  /* Your styles here */
  .product-card {
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    background-color: var(--secondary-text-color);
    transition: box-shadow 0.2s ease;
    width: 33%;
    margin: 0;
    height: 260px;
  }
}

@media (max-width: 1560px) {
  .cash-popup-box {
    padding: 30px;
  }

  .product-image img {
    width: auto;
    height: 75px;
  }

  .keypad button {
    width: 60px;
    height: 60px;
  }

  .order-box .ant-card-body p.status-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-catalog {
    overflow-y: auto;
    max-height: 624px;
    padding-bottom: 130px;
    flex-wrap: nowrap;
  }

  .product-card {
    width: 22%;
  }
}

@media (max-width: 1490px) {
  .popup-row {
    font-size: 25px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: var(--border-color);
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  .quick-order-btn {
    padding: 0 16px;
    font-size: 15px;
  }

  .cart-body {
    max-height: 385px;
  }

  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }

  .popup-row input {
    font-size: 25px;
  }

  .order-box {
    height: 180px;
    width: 45%;
  }

  .order-box .ant-card-body p.status-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .customer-list li p {
    font-size: 25px;
  }

  .account-page .content input[type="text"],
  .account-page .content input[type="password"] {

    width: 100%;
  }

  .account-page .content button {

    width: 100%;
  }

  .profile-sidebar {
    width: 25%;
  }

  .cart-header {
    /* flex-direction: column; */
    height: auto;
    flex-wrap: wrap;
    justify-content: center !important;
    /* padding: 10px 0; */
  }

  .cart-header .cart-head-right {
    border: 0;
  }

  .cash-popup-box {
    padding: 30px;
  }

  .keypad button {
    width: 50px;
    height: 50px;
  }

  .cart-search-cont {
    width: 100%;
  }

  .cart-body {
    gap: 7px;
  }

  .cart-footer {
    gap: 14px;
    display: flex;
    flex-direction: column;
  }

  .cart-sub-total {
    padding: 9px 0px;
  }

  .cart-summary {
    padding-top: 7px;
  }

  /* .order-controls button {
    padding: 9px 15px;
    font-size: 22px;
    font-weight: 500;
  } */
  .order-controls button {
    padding: 0px 4px;
    font-size: 18px;
    font-weight: 500;
  }
  .order-controls button img {
    opacity: 0.4;
    width:34px;
  }
}



@media (max-width: 1390px) {
  .product-image img {
    width: auto;
    height: 81px;
  }

  .product-price {
    color: var(--primary-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .add-button {
    border: none;
    padding: 2px 7px;
    height: auto;
    border-radius: 100px;
    background: var(--primary-color);
    color: var(--secondary-text-color);
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.645px;
  }

  .product-card {
    width: 32%;
  }

  .customer-list li {
    display: flex;
    width: 45%;
    height: 150px;
    padding: 25px 20px;
    flex-direction: column;
    border-radius: 6.956px;
    border-left: 6px solid var(--Primary, var(--primary-color));
    background: var(--secondary-text-color);
    align-items: flex-start;
    gap: 26.954px;
    justify-content: center;
  }

  .main-cont h1 {
    text-align: left;
    color: #000011;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 50px;
  }

  .promocode label {
    color: #576474;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }

  /* .order-controls button {
    padding: 9px 19px;
    font-size: 22px;
    font-weight: 500;
  } */
  .cart-footer-cards {
		bottom: -35px;
	}

	.cart-body {
		max-height: 294px;
	}

	span.cart-item-qty.quantity-control span {
		font-size: 12px;
	}

	span.cart-item-totalprice {
		flex: 1;
		text-align: right;
	}

	.cart-footer-cards {
		display: flex;
		justify-content: space-between;
		background: var(--secondary-color);
		height: 70px;
		align-items: center;
		padding: 0 10px;
		color: var(--secondary-text-color);
		white-space: nowrap;
		gap: 8px;
		position: sticky;
		bottom: 0;
		z-index: 10;
	}
  .order-controls {
    gap: 7px;
  }
  .order-controls button {
    padding: 0px 4px;
    font-size: 14px;
    font-weight: 500;
  }
  .order-controls button img {
    opacity: 0.4;
    width: 30px;
  }

  /* .cart-footer-cards {
    /* bottom: -35px; 
  } */

  /* .cart-body {
    max-height: 294px;
  } */

  span.cart-item-qty.quantity-control span {
    font-size: 12px;
  }

  span.cart-item-totalprice {
    flex: 1;
    text-align: right;
  }
}

@media (max-width: 1290px) {
  .cart-body {
    /* max-height: 321px; */
    height: 394px;
    background-color: #fff;
  }

  .product-catalog {
    overflow-y: auto;
    max-height: 624px;
    padding-bottom: 220px;
    flex-wrap: nowrap;
  }
}

@media (max-width: 1200px) {
  .product-image img {
    width: auto;
    height: 70px;
  }

  .product-price {
    color: var(--primary-text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .add-button {
    border: none;
    padding: 2px 7px;
    height: auto;
    border-radius: 100px;
    background: var(--primary-color);
    color: var(--secondary-text-color);
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.645px;
  }

  .popup-main {
    flex-direction: column;
  }

  .popup-left-cont {
    width: 100%;
  }

  .keypad {
    width: 100%;
  }

  .popup-row {
    height: 65px;
    padding: 0 15px;
    font-size: 20px;
  }

  .popup-left-cont {
    gap: 20px;
  }

  .customer-list li p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #576474;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  .customer-list li p .cus-email {
    color: #576474;
    font-size: 19px;
    font-weight: 400;
  }
}

@media (max-width: 1198px) {
  .order-controls button {
    padding: 9px 6px;
    font-size: 20px;
    font-weight: 500;
  }
}

@media (min-width: 1023px) and (max-width: 1197px) {
  .order-controls button {
    padding: 9px 6px;
    font-size: 20px;
    font-weight: 500;
  }
  .order-controls button img {
     display: none;
  }
}

@media (max-width: 1024px) {
  .promo-code-header {
    padding: 30px 30px;
  }

  body {
    overflow-y: auto;
  }

  .couponCodesList {
    padding: 30px 30px;
  }

  .promoCodeContainer input {
    padding: 0 15px;
    font-size: 19px;
  }

  .promoCodeContainer button.ant-btn.ant-btn-default {
    padding: 0 15px;
  }

  .coupon-code-box .coupon-left {
    width: 65px;
  }

  .coupon-right {
    padding: 15px 15px;
  }

  .promocode label {
    font-size: 13px;
  }

  /* .promocode { 
  flex-direction: column; 
}.promocode div {
  width: 100%;
} */
  .order-controls button {
    padding: 15px 15px;
  }

  .login-screen h1 {
    font-size: 40px;
  }

  .customer-list li {
    width: 46%;
  }

  .sidebar {
    width: 45%;
  }

  .product-card {
    width: 45%;
  }

  .profile-sidebar {
    width: 35%;
  }

  .searchField input {
    min-width: 400px;
  }

  .cart-button {
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #1890ff;
    color: var(--secondary-text-color);
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }

  .cart-container {
    display: none;
  }

  .cart {
    width: 100%;
  }

  .cart-container.cart-visible {
    display: block;
    width: 100%;
    position: absolute;
    margin: 0;
  }

  .cart-header {
    height: auto;
    flex-direction: column;
    padding: 10px 0 0;
  }

  button.ant-btn-default.ant-btn-icon-only.cart-button {
    position: fixed;
    right: 0;
    bottom: 0;
    background: #ffd400 !important;
    width: 70px;
    height: 70px;
    border-radius: 2rem;
    border: none;
    opacity: 0.5;
  }

  button.ant-btn-default.ant-btn-icon-only.cart-button:hover {
    opacity: 1;
  }

  .anticon svg {
    display: inline-block;
    color: #000;
    font-size: 19px;
  }

  .cart-head-right {
    border-left: 0;
  }

  .order-controls button {
    height: 55px;
    padding: 16px 23px;
    gap: 10px;
  }

  .account-page .content input[type="text"],
  .account-page .content input[type="password"] {
    width: 100%;
  }

  .account-page .content button {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .quick-order {
    flex-direction: row;
  }

  .cart-page-layout .footer {
    position: fixed;
    box-shadow: -7px -8px 16px 0px #00000026;
  }

  .cart-body {
    gap: 14px;
  }

  .cart-sub-total {
    padding: 1px 0px;
  }

  /* .order-controls {
    margin-bottom: 14px;
  } */
  .order-controls button {
    padding: 0px 4px;
    font-size: 14px;
    font-weight: 500;
  }
  .order-controls button img {
    opacity: 0.4;
    width: 30px;
  }

  
}
@media(max-width:940px) {
  
  .order-box .ant-card-body p {
    font-size: 15px;
  }
  .order-box .ant-card-body span {
		font-size: 15px;
	}


  .order-box .ant-card-body p.status-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-box .ant-card-body p.order-date {
    margin: 0 0;
    font-size: 15px;
  }

  
}

@media (max-width: 768px) {
  .button-container {
		flex-direction: column;
		bottom: 10px;
		right: 10px;
	}

	.icon-button {
		margin-bottom: 10px;
	}
  .cash-popup-box {
    width: 65%;
  }

  .cart-container {
    z-index: 1000;
  }

  .customer-list li p {
    font-size: 15px;
  }

  .customer-list li p .cus-email {
    font-size: 15px;
  }

  .searchField input {
    font-size: 16px;
  }

  .heading-cont {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .searchField input {
    display: flex;
    min-width: 90%;
    max-width: 90%;
  }



  .order-box .ant-card-body p {
    font-size: 15px;
  }
  .order-box .ant-card-body span {
		font-size: 15px;
	}


  .order-box .ant-card-body p.status-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-box .ant-card-body p.order-date {
    margin: 0 0;
    font-size: 15px;
  }



  .footer-nav li .footer-nav-item {
    flex-direction: column;
    align-items: center;
  }


  /* Burger menu styles */
  .burger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }

  .burger-bar {
    width: 25px;
    height: 3px;
    background-color: var(--secondary-text-color);
    margin: 4px 0;
  }

  .customer-list li {
    width: 45%;
    height: 120px;
    padding: 25px 20px;
    gap: 20px;
  }


  .search-container {
    display: flex;
    width: 100%;
  }

  .search-container,
  .header-user-profile {
    display: none;
  }

  .burger-menu {
    display: flex;
  }

  .burger-menu.open .burger-bar:nth-child(2) {
    opacity: 0;
  }

  .burger-menu.open .burger-bar:nth-child(1) {
    transform: translateY(15px) rotate(45deg);
  }

  .burger-menu.open .burger-bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }

  .mobile-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: #ffd400;
    font-size: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    z-index: 1001;
  }

  .mobile-menu.open {
    display: flex;
    flex-direction: column;
  }

  .mobile-menu a {
    padding: 10px;
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid #eee;
  }

  .mobile-menu a:last-child {
    border-bottom: none;
  }

  .login-form {
    width: 100%;
  }

  .cart-page-layout {
    flex-direction: column;
  }




  .product-catalog h2 {
    font-size: 25px;
  }

  .product-card {
    width: 100%;
  }

  .menu-button {
    display: block;
  }




  .ant-drawer .ant-drawer-title {
    font-size: 25px;
  }

  .ant-drawer .ant-drawer-close {
    right: 15px;
    position: absolute;
  }


  button.ant-modal-close span.ant-modal-close-x {
    margin: -5px -16px;
  }

  .edit-customer-modal.ant-modal .ant-modal-body {
    padding: 20px;
  }

  .ant-modal .ant-modal-footer {

    padding: 0 20px;
  }

  .edit-customer-modal.ant-modal input[type="text,email"] {
    font-size: 22px;
  }

  .edit-customer-modal.ant-modal input[type="email"] {
    font-size: 22px;
  }



  .product-popup {
    padding: 20px;
  }

  .heading-img h2 {
    margin-top: 10px;
  }

  .heading-img span {
    margin-right: 0;
    width: 100%;
  }

  .heading-img {
    flex-direction: column;
  }

  .popup-qty .quantity-control {
    height: 35px;
  }

  .popup-footer {
    padding: 10px 15px;
  }

  .btn-total {
    font-size: 10px;
  }

  .btn-total span {
    font-size: 20px;
  }

  .popup-footer button {
    font-size: 20px;
  }

  .ant-modal .ant-modal-close {
    width: 48px;
    height: 48px;
    margin: -30px -35px;
  }

  .profile-sidebar {
    width: 100%;
  }

  .account-page .content-right {
    margin-top: 90px;
  }
}

@media (max-width: 735px) {
  ul.order-items li .return-qty {
    padding: 0;
  }

  .quick-order {
    padding: 20px 5px;
    gap: 5px;
    flex-direction: column;
  }

  .order-box {
    width: 100%;
    height: 175px;
  }



}

@media (max-width: 600px) {

  ul.order-items li .return-qty {
    padding: 0;
  }

  .quick-order {
    padding: 20px 5px;
    gap: 5px;
    flex-direction: column;
  }

  .cart-search-cont {
    width: 100%;
  }

  .cash-popup-box {
    width: 95%;
    padding: 25px;
  }

  .popup-row input {
    font-size: 20px;
  }

  .product-catalog {
    padding: 80px 20px;
    width: 100%;
    align-items: center;
  }

  .sidebar {
    width: 100%;
  }

  .order-box {
    width: 100%;
    height: 175px;
  }

  .tab-inner-cont {
    flex-direction: column;
  }

  .customer-list li {
    width: 100%;
    height: 120px;
    padding: 25px 20px;
    gap: 20px;
  }

  .main-cont {
    padding: 20px;
  }

  .profile-sidebar {
    width: 100%;
  }

  .footer-nav {
    gap: 35px;
  }

  .footer-nav li .footer-nav-item span {
    display: none;
  }

  .account-page .content-right {
    margin-top: 90px;
  }

  .ant-tabs-nav .ant-tabs-tab-btn span {
    padding: 0 15px;
    height: 60px;
    font-size: 20px;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab,
  .ant-tabs .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: space-evenly;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    gap: 10px;
  }

  .main-cont h1 {
    font-size: 45px;
    padding-bottom: 10px;
  }

  button.close-calc {
    margin: -45px -10px;
  }

  .cart-sub-total {
    padding: 11px 0px;
  }

  .order-controls {
    margin-bottom: 4px;
  }
}

@media (max-width: 420px) {
  .footer {
    padding: 0px;
  }
}

@media (max-width:397px){
  .order-controls button {
    padding: 0px 4px;
    font-size: 14px;
    font-weight: 500;
  }
  .order-controls button img {
    display: none;
  }
}

@media (max-width: 320px) {
  .popup-row {
    font-size: 13px;
  }

  .keypad button {
    height: 50px;
    width: 50px;
  }

  .keypad button.clearbtn {
    font-size: 15px;
  }

  .keypad button {
    font-size: 25px;
  }

  .ant-tabs-nav .ant-tabs-tab-btn span {
    padding: 0 10px;
    height: 50px;
    font-size: 15px;
  }

  .customer-list li p {
    flex-direction: column;
  }

  .customer-list li {
    height: 160px;
  }
}

.filter-section {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  /* Align items to the start to prevent overflow */
}

.filter-btn {
  color: black;
  background-color: white;

  padding: 10px 15px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
  /*Adjust margin to stack the button above the dropdown*/
}

.filter-btn:hover {
  padding: 10px 15px;
  background-color: white;
  color: black;

  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
  /* Adjust margin to stack the button above the dropdown */
}

.filter-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: auto;
  top: 40px;
  right: 20px;

  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
  padding: 10px;
  width: 300px;
  /* Set a fixed width to prevent overflow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
}

.filter-dropdown input,
.filter-dropdown select {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  /* Make inputs full width within the dropdown */
}

.apply-btn {
  background-color: red;
  font: bold;

  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

/* 
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
}

.popup-title {
  color: #78be20;
  margin-bottom: 5px;
}

.popup-subtitle {
  color: #444;
  margin-bottom: 20px;
}

.product-info {
  margin-bottom: 20px;
}

.product-quantity {
  font-weight: bold;
}

.quantity-value {
  color: red;
}

.warehouse-name {
  font-weight: bold;
}

.warehouse-value {
  color: #444;
}

.warehouse-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.warehouse-table th,
.warehouse-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.cancel-button {
  background-color: #888;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */


.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  /* padding: 30px; */
  border-radius: 10px;
  /* width: 450px; */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: popup 0.3s ease-in-out;
}

@keyframes popup {
  from {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.popup-title {
  color: #78be20;
  margin-bottom: 10px;
  font-size: 24px;
}

.popup-subtitle {
  color: #444;
  margin-bottom: 20px;
  font-size: 18px;
}

.product-info {
  margin-bottom: 20px;
}

.product-quantity {
  font-weight: bold;
  margin-bottom: 10px;
}

.quantity-value {
  color: red;
}

.warehouse-name {
  font-weight: bold;
  margin-bottom: 20px;
}

.warehouse-value {
  color: #444;
}

.warehouse-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.warehouse-table th,
.warehouse-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.warehouse-table th {
  background-color: #f2f2f2;
}

.warehouse-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.close-button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: white;
  color: #000;
  border: 1px solid black;
  font: bold;

}


/* Increase button size */
.pagination button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Red button with white text */
.pagination button.next {
  background-color: red;
  color: white;
}

/* Hover effect for previous button */
.pagination button.prev {
  background-color: #ddd;
  color: black;
}

.pagination button.prev:hover {
  background-color: red;
  color: white;
}

/* Disabled button styles */
.pagination button:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

/* CustomerPage.css */


.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


.pagination button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button.prev {
  background-color: #ddd;
  color: black;
}

.pagination button.next {
  background-color: red;
  color: white;
}

.pagination button.prev:hover,
.pagination button.next:hover {
  background-color: red;
  color: white;
}

.pagination button:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

.pagination .page-number {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f8f8f8;
  color: black;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  min-width: 60px;
}


.student_checkbox_div {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  justify-content: space-around;
}

.student_box {
  width: 2rem !important;
  box-shadow: none !important;
}
.unique-popup-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  /* padding: 32rem 0rem; */
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
  overflow-y: scroll;
}

.unique-popup-content {
	background-color: #fff;
	padding: 30px;
	border-radius: 10px;
	width: 450px;
	text-align: center;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	animation: unique-popup 0.3s ease-in-out;
}

@keyframes unique-popup {
	from {
		transform: scale(0.9);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}

.unique-popup-title {
	color: #78be20;
	margin-bottom: 10px;
	font-size: 24px;
}

.unique-popup-subtitle {
	color: #444;
	font: bold;
	margin-bottom: 20px;
	font-size: 24px;
}

.unique-product-info {
	margin-bottom: 20px;
}

.unique-product-quantity {
	font-weight: bold;
	margin-bottom: 10px;
}

.unique-quantity-value {
	color: red;
}

.unique-warehouse-name {
	font-weight: bold;
	margin-bottom: 20px;
}

.unique-warehouse-value {
	color: #444;
}

.unique-warehouse-table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}

.unique-warehouse-table th,
.unique-warehouse-table td {
	border: 1px solid #ccc;
	padding: 8px;
	text-align: left;
}

.unique-warehouse-table th {
	background-color: #f2f2f2;
}

.unique-warehouse-table tr:nth-child(even) {
	background-color: #f9f9f9;
}

.unique-close-button {
	background-color: var(--primary-color);
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.unique-close-button:hover {
	background-color: white;
	color: #000;
	border: 1px solid black;
	font-weight: bold;
}

/*  Login Page PopUp */
/* General popup styling */
/* .popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
} */

/* .popup-inner {
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
} */

/* Styling for the input groups */
/* .input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
} */

/* Button styling */
/* button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}

/* Result styling */
.result {
	margin-top: 20px;
	font-weight: bold;
}

.success {
	color: green;
}

.failure {
	color: red;
}

/* Responsive design */
/* @media (max-width: 600px) {
  .popup-inner {
    padding: 15px;
  }

  .input-group input {
    padding: 8px;
  }

  button {
    padding: 8px;
    font-size: 14px;
  }
} */

/* Taxes Header   */

.taxes-span-header {
	/* display: flex; */
	align-self: flex-start;
	display: flex;
	width: 100%;
	/* padding: 15px 0px; */
	justify-content: space-between;
	align-items: flex-start;
	border-top: 1px solid #576474;
	/* border-bottom: 1px solid #576474; */
	color: var(--primary-text-color);
	font-size: 16px;
	font-style: normal;
	/* font-weight: 500; */
	line-height: 20px;
}

.taxes-header {
	display: flex;
	align-self: flex-start;
	display: flex;
	width: 100%;
	/* padding: 15px 0px; */
	justify-content: space-between;
	align-items: flex-start;
	border-top: 1px solid #576474;
	/* border-bottom: 1px solid #576474; */
	color: var(--primary-text-color);
	font-size: 16px;
	font-style: normal;
	/* font-weight: 500; */
	line-height: 20px;
}

/* .taxes-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    color: #232f3e;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    gap: 8px;
    display: flex; 
    flex-direction: column; 
}*/

.taxes {
	width: 100%;
}

.tax-container {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	font-size: 14px;
	color: #444;
	width: 100%;
}

.tax-label {
	margin: 0;
}

.tax-value {
	margin: 0;
}

/* LoginPopupUnique.css */

.login-screen-unique {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.login-form-unique {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.form-group-unique {
	width: 100%;
	margin-bottom: 16px;
}

#email-unique,
#password-unique {
	width: 100%;
}

.ant-btn-primary-unique {
	width: 100%;
	background-color: red;
	border-color: red;
}

.eye-icon-unique {
	font-size: 1.5em;
}

@media (max-width: 600px) {
	.eye-icon-unique {
		font-size: 1.2em;
	}
}

/* ------------------------ReceiptCSS start---------------------- */
/* .wrapper_receipt {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #f0f0f0;
	font-family: 'Arial', sans-serif;
} */
.wrapper_receipt {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 32rem 0rem;
  overflow-y: scroll;
}

.container_receipt {
	width: 300px;
	padding: 20px;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.maindiv_receipt {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.logo_img {
	width: 80px;
	height: auto;
	margin-bottom: 10px;
}

.gelato_heading__receipt {
	font-size: 18px;
	font-weight: bold;
	margin: 10px 0;
}

.address_receipt,
.sub_address_receipt {
	text-align: center;
}

.para_receipt {
	margin: 5px 0;
	font-size: 12px;
}

.hr {
	border: none;
	border-top: 1px dashed #000;
	margin: 15px 0;
}

.middle_div {
	margin-top: 15px;
}

.date_receipt,
.sold_to,
.ship_to {
	margin-bottom: 15px;
}

.list_receipt {
	padding-left: 20px;
	margin: 10px 0;
}

.sublist__receipt {
	display: flex;
	justify-content: space-between;
}

.subtotal_receipt {
	display: flex;
	justify-content: space-between;
	margin: 5px 0;
}

/* Add some emphasis to the total */
.subtotal_receipt:nth-child(4) {
	font-weight: bold;
	font-size: 14px;
}

/* Style for the last section */
.container_receipt>div:last-child {
	text-align: center;
	margin-top: 15px;
}

/* Responsive design */
@media (max-width: 400px) {
	.container_receipt {
		width: 90%;
		padding: 10px;
	}
}



/* ------------------------ReceiptCSS start---------------------- */
/* .wrapper_receipt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  min-height: 100vh;
} */

.container_receipt {
  border: 2.5px solid;
  width: max-content;
  padding: 1rem 3rem;
}

.container_receipt .maindiv_receipt {
  display: flex;
  gap: 2rem;
}

.container_receipt .maindiv_receipt .address_receipt {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container_receipt .maindiv_receipt .address_receipt .gelato_heading__receipt {
  text-align: left;
  letter-spacing: 0.5rem;
  font-weight: 700;
}

.container_receipt .para_receipt {
  margin-bottom: 0rem;
}

.container_receipt .maindiv_receipt .LogoXelato_receipt {
  width: 5rem;
  height: 5rem;
}

.container_receipt .maindiv_receipt .LogoXelato_receipt .logo_img {
  width: 100%;
  height: 100%;
}

.container_receipt .middle_div {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.container_receipt .middle_div .date_receipt {
  text-align: left;
}

.middle_div .list_receipt .sublist__receipt {
  display: flex;
  /* gap: 5rem; */
  justify-content: space-between;
  align-items: center;
}

.middle_div .subtotal_receipt {
  display: flex;
  justify-content: right;
  gap: 1.5rem;
}

.hr {
  margin: initial
}

.area_receipt {
  padding: 1rem 0rem;
}
/* Print Receipt Button Start */
.success-modal .print-button-for-receipt {
  display: block; /* Block-level element for easy centering */
  margin: 10px auto; /* Centers the button horizontally and adds top/bottom margin */
  background-color: red; /* Red background */
  color: white; /* White text color */
  height: 48px;
  font-weight: bold; /* Bold text */
  padding: 0px 40px; /* Increases button height and width */
  font-size: 16px; /* Increases font size */
  border: none; /* Removes the border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center !important; /* Centers text inside the button */
}

.success-modal:hover .print-button-for-receipt:hover {
  background-color: darkred;
  color: white;
  border-color: black; /* Darker red on hover */
}

 

/* Print Receipt Button End */

/* ------------------------ReceiptCSS end---------------------- */


.print_button {
  width: 20%;
  /* height: 60px; */
  border: 1px solid;
  padding-top: 2px;
}

.print_button span {
  font-weight: 700;
}
/* .print_button :hover{
  background-color: red;
  font: bold;
  color: white;
} */

.printdiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .printbtn {
  border: none;
  font-size: 0.7rem;
  margin: 0rem;
  font-weight: 700;
  cursor: pointer;
  background: #7AB7E0;
  color: #fff;
  border-radius: 0rem 0.5rem;
} */

.moberror{
  color: red;
  
}
/* ----------css for PaginationforOrders -----------------*/
.pagination-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.pagination-buttons {
	display: flex;
	align-items: center;
}

.ant-pagination {
	margin: 0 10px;
	/* Adjust margin as needed */
}

.ant-pagination-item,
.ant-pagination-item-active {
	border: 1px solid #1890ff;
	/* Box border */
	border-radius: 4px;
	/* Rounded corners */
	padding: 0 10px;
	/* Padding inside the box */
	font-size: 14px;
	color: #1890ff;
	/* Text color */
}

.ant-pagination-prev,
.ant-pagination-next {
	border: none;
	background: none;
	font-size: 14px;
  background-color: #ddd;
  color: black;
	/* Text color */
}

.prev {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 0 10px;
	font-size: 14px;
  /* background-color: #ddd; */
  color: black;
	cursor: pointer;
	background: #fff;
	margin: 0 5px;
}
.next {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 0 10px;
	font-size: 14px;
  /* background-color: #ddd; */
  color: white;
	cursor: pointer;
	background: red;
	margin: 0 5px;
}
.prev:hover,
.next:hover {
  background-color: red;
  color: white;
}

.prev:disabled,
.next:disabled {
	color: #d9d9d9;
	cursor: not-allowed;
}

/* Reciept Css STARTS */
/* .wrapper_receipt {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.container_receipt {
  width: 300px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  font-family: 'Courier New', Courier, monospace;
}

.maindiv_receipt {
  text-align: center;
  margin-bottom: 20px;
}

.LogoXelato_receipt {
  margin-bottom: 10px;
}

.logo_img {
  width: 60px;
  height: auto;
}

.gelato_heading__receipt {
  font-size: 1.2em;
  margin: 10px 0;
}

.address_receipt {
  font-size: 0.8em;
}

.sub_address_receipt p {
  margin: 2px 0;
}

.hr {
  border: none;
  border-top: 1px dashed #000;
  margin: 10px 0;
}

.middle_div {
  font-size: 0.9em;
}

.date_receipt {
  margin-bottom: 10px;
}

.sold_to, .ship_to {
  margin-bottom: 10px;
}

.list_receipt {
  padding-left: 20px;
  margin: 10px 0;
}

.sublist__receipt {
  display: flex;
  justify-content: space-between;
}

.subtotal_receipt {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.para_receipt {
  margin: 2px 0;
}


.sold_to, .ship_to {
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 10px;
}

.sold_to p:first-child, .ship_to p:first-child {
  font-weight: bold;
  text-decoration: underline;
}

.subtotal_receipt p:first-child {
  font-weight: bold;
}

.subtotal_receipt:last-child {
  border-top: 1px solid #000;
  padding-top: 5px;
  margin-top: 5px;
} */
.receipt {
	font-family: 'Courier New', Courier, monospace;
	font-size: 12px;
	line-height: 1.2;
	background-color: white;
	padding: 20px;
	width: 300px;
	margin: 20px auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.receipt>div {
	margin-bottom: 10px;
}

.store-name {
	font-weight: bold;
	text-align: center;
}

.store-address {
	text-align: center;
}

.invoice-details,
.sold-to,
.ship-to,
.email,
.order-items,
.totals,
.payment,
.tax-info {
	border-top: 1px dashed #000;
	padding-top: 10px;
}

.date-time {
	float: right;
}

.section-header {
	text-align: center;
	font-weight: bold;
}

.item,
.subtotal,
.tax,
.total,
.payment {
	display: flex;
	justify-content: space-between;
}

.total {
	font-weight: bold;
}

.footer {
	text-align: center;
	font-weight: bold;
	margin-top: 20px;
}

/* Reciept Css ENDS */

.print_button {
	width: 50%;
	height: 60px;
	border: 1px solid;
}

.print_button span {
	font-weight: 700;
}

.printdiv {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.printbtn {
	border: none;
	font-size: 0.7rem;
	margin: 0rem;
	font-weight: 700;
	cursor: pointer;
	/* background: var(--primary-color); */
	color: #fff;
	border-radius: 0rem 0.5rem;
}


/* Item Price Change PopUp css Start */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	background: #f8f3ff;
	padding: 20px;
	border-radius: 10px;
	width: 300px;
	position: relative;
	text-align: center;
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 20px;
	cursor: pointer;
}

h2 {
	margin-bottom: 20px;
	font-family: 'Arial', sans-serif;
	font-size: 18px;
}

.input-group {
	margin-bottom: 15px;
}

.input-group label {
	display: block;
	margin-bottom: 5px;
	font-family: 'Arial', sans-serif;
	font-size: 14px;
}

.input-group input {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 16px;
}

.update-button {
	background: #e7004c;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
}

.update-button:hover {
	background: #cc0041;
}
/* Item Price Change PopUp css End */

.cart-item-unitprice span {
	cursor: pointer;
}

.cart-item-unitprice .ant-input {
	width: 80px;
}







.promo-code-button{
  background-color: white !important;
}



.countrycode{
  text-align: center;
  padding: 0rem 0.3rem;
  margin: initial;
  margin-top: 0.3rem;

}
.countrycodespan{
  padding: 0rem 0.3rem;
}

.customerNumber {
  display: flex;
  align-items: center;
  color: var(--border-color);
  font-size: 22px;
  font-weight: 500;
  border: 1px solid var(--border-color);
  border-radius: 10px 10px 10px 10px;

}
.change-language-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.change-language-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.custom-dropdown {
  width: 300px !important; /* Make the dropdown wider */
  height: 50px; /* Increase height */
  font-size: 18px; /* Larger font for options */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  background-color: #f5f5f5; /* Light background color */
  margin:20px;/*
}

.custom-dropdown .ant-select-selector {
  height: 50px !important; /* Align dropdown height */
  display: flex;
  align-items: center; /* Center the text vertically */
  font-size: 18px; /* Adjust text size */
}

.custom-dropdown .ant-select-selection-item {
  line-height: 50px !important; /* Align selected text vertically */
}

.custom-dropdown:hover {
  border-color:red; /* Change border color on hover */
}

.custom-dropdown .ant-select-dropdown {
  max-height: 400px; /* Max height for long lists */
  border-radius: 8px; /* Rounded corners for dropdown list */
}

.custom-dropdown .ant-select-item {
  padding: 10px 20px;
  font-size: 16px;
}
 
.change-password-btn{
  border: 1px solid #d9d9d9 !important;
	border-radius: 4px;
	padding: 0 10px;
	font-size: 14px;
  /* background-color: #ddd; */
  color: white !important;
	cursor: pointer;
	background: red !important;
	margin: 0 5px;
}



/* .change-password {
  max-width: 400px;
  margin: 0 auto;
}

.password-input-container {
  position: relative;
  margin-bottom: 16px;
}

.password-input-container input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
} */

.password-toggle-icon {
  /* position: absolute; */
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
}

.password-toggle-icon:hover {
  color: #333;
}

/* .change-password-btn {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.change-password-btn:hover {
  background-color: #45a049;
}

.change-password-btn:focus {
  outline: none;
} */
